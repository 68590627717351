import './bootstrap.js'
import '../css/app.scss'
import 'react-datepicker/dist/react-datepicker.css'

import { createInertiaApp } from '@inertiajs/react'
import { createRoot } from 'react-dom/client'
import React from 'react'
import * as Sentry from '@sentry/react'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'

createInertiaApp({
  resolve: name =>
    resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob('./Pages/**/*.tsx'),
    ),
  setup({ el, App, props }) {
    const root = createRoot(el)

    root.render(<App {...props} />)

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      logErrors: true,
    })
  },
  progress: {
    includeCSS: false,
  },
})
